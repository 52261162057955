<script>
import Vue from "vue";

  const BaseBaliseRef = Vue.extend({
    name:"BaseBaliseRef",
     template: '<b-link class="balise-ref" :href="href" :to="to" :title="tooltip" v-b-tooltip.hover><slot>&lt;<slot name="balise-name">{{balise}}</slot>&gt;</slot></b-link>',
      props:{
        balise: { type:String, required: true },
        href: { type:String, default: null},
        to: { type:Object, default: null },
        tooltip: { type:String, default:null},
      },
  });

    const BCollapseRef = Vue.extend({
      name:"BCollapseRef",
      components: { BaseBaliseRef },
      template: "<base-balise-ref balise='B-Collapse' href='https://bootstrap-vue.org/docs/components/collapse' tooltip='Composant \"Collapse\" de Bootstrap'></base-balise-ref",
    });

export default { BaseBaliseRef, BCollapseRef };
//export { BaliseRef, BCollapseRef };
</script>
