<template>
  <div>
    <h1 class="flex">Charte graphique &amp; composants</h1>
    <a id="formulaires"><h2>Formulaires &amp; champs de formulaire</h2></a>
    <h3>Formulaire</h3>
    <p>Les formulaires, et plus généralement les pages du site et des applications intégrées, devraient reposer sur une
      utilisation de Bootstap et de ses composants (ou composant "maisonn" eux-même reposant sur les technologies
      bootstrap, et ceci afin de garantir une cohérence de style dans l'ensemble des pages et ainsi améliorer l'expérience utilisateur.</p>
    <p>En particulier, pour les formulaires et la plupart des composants utilisés dans ceux-ci, il convient d'utiliser la grille bootstrappouur gérer
      au mieux la fluidité et la lisibilité des données.<br/>
      Autant que faire se peut, on s'interdira des tailles trop petites ou trop grandes de composants, le but étant que le site et les applicatioons initégrées
      soient totalement responsive et puisse être consultés aussi bien sur téléphone, tablette ou ordinateur.
    </p>
    <p>Le plus souvent possible il conviendra de découper les données d'un formulaire par thèmes ou associations techniques en ayant recours
      à des paneaux rétractables pour permettre à l'utilisateur de gérer l'affichage un peu comme bon lui semble et masquer des parties potentiellement
      grandes et gênantes pour visualiser ou éditer une partie qui l'intéresse plus.<br/>
      A cette fin, outre la classique <base-balise-ref balise="Test" /> <b-collapse-ref/> de Bootstrap, un panneau rétractable spécifique a été développé.<br/>
      Il se trouve dans <b><i>@/components/Panels/CollapsePanel.vue</i></b>.<br/>
    </p>
    <a id="CollapsePanel"><h4>collapse-panel</h4></a>
      <p>
        L'utilisation, le plus largement possible de ce panneau rétractable, permettra de garantir, à peu de frais de développement, l'expérience utilisateur qui retrouvera ainsi
        les même fonctionnalités et styles tout au long de sa navigation.
        Pour le mêttre en oeuvre, rien de plus simple :
        <ul>
          <li>Après avoir importé le module dans le composant ou la page qui doit l'utiliser</li>
          <li>et référencé celui-ci dans les components</li>
          <li>il suffit de le déclarer là ou on veut le retrouver</li>
        </ul>
      </p>
      <p>
        <b>Démo</b><br/>
        <b-row>
          <b-col md="6">
            <fieldset class="fieldset">
              <legend>Configuration</legend>
              <form-field :compactMode="true" caption="Titre">
                <b-input type="text" v-model="sample_panel_title" />
              </form-field>
              <b-checkbox v-model="sample_panel_collapsable" switch>Est rétractable ?</b-checkbox>
              <b-checkbox v-model="sample_panel_visible" switch>Est visible (par défaut ou programmatiquement) ?</b-checkbox>
            </fieldset>
          </b-col>
          <b-col md="6">
            <collapse-panel
              :title="sample_panel_title"
              :collapsable="sample_panel_collapsable"
              :visible="sample_panel_visible"
            >
            Le texte suivant correspond au slot par défaut.
            </collapse-panel>
          </b-col>
        </b-row>
      </p>


    <a id="Champs"><h2>Champs de formulaire</h2></a>
    <p>Pour les champs de formulaire, là aussi, il est recommandé de toujours utiliser les contrôles Bootstrap afin de gérer convenablement la saisie.
    Cependant, ce qui est particulièrement pénible, répétitif... c'est ce qui accompagne le champs de saisie proprement dit, à savoir :
    <ul>
      <li>son libellé,</li>
      <li>son éventuel astérisque (ou autre) pour indiquer que celui-ci est requis,</li>
      <li>son éventuel info-bulle (d'aide rapide)</li>
      <li>une éventuelle dialogue plus complète d'information/aide associée au champ,</li>
      <li>l'affichage des éventuels messages de validation,</li>
      <li>...</li>
    </ul>
    Et le plus ennuyeux de tout cela c'est d'arriver à garantir que la restitution de tout ceci sera identique d'une page à une autre, ou d'un formulaire à un autre.
    </p>
    <p>Et si finalement le style choisi au début ne correspond plus au besoin ou que l'on souhaite le faire évoluer...</p>
    <p>Ou si, encore, on souhaitait se donner la possibilité d'un affichage un peu différent selon que l'on est sur PC ou tablette ou téléphone, ou même, en fonction de préférences de l'utilisateur...</p>
    <p>Le seul moyen de garantir cela c'est de proposer un composant "enveloppe" qui propose tout cela tout en encapsulant le composant de saisie et, bien sûr, d'en généraliser l'utilisationo.<br/>
    C'est ce qui a été fait avec le composant maison form-field que l'on trouve sous <b><i>@/components/panels/FormField.vue</i></b>.</p>
    <a id="FormField"><h4>form-field</h4></a>
    <p><b>Démo</b><br/>
      <b-row>
        <b-col md="6">
          <fieldset class="fieldset">
            <legend>Configuration</legend>
            <form-field :compactMode="true" caption="Libellé"><input type="text" v-model="sample_formfield_caption"/></form-field>
            <b-row>
              <b-col md="4"><b-checkbox switch v-model="sample_formfield_required">Obligatoire</b-checkbox></b-col>
              <b-col md="4"><b-checkbox switch v-model="sample_formfield_compactmode">Mode compact</b-checkbox></b-col>
              <b-col md="4"><b-checkbox switch v-model="sample_formfield_infohelper">Avec aide</b-checkbox></b-col>
            </b-row>
          </fieldset>
        </b-col>
        <b-col md="6">
          <form-field
            :caption="sample_formfield_caption"
            :required="sample_formfield_required"
            :compactMode="sample_formfield_compactmode"
          >
            <input type="text" value="Zone de saisie du texte (encapsulée)"/>
            <template #info-helper-placeholder>
              <info-helper v-if="sample_formfield_infohelper">
                Voici une aide qui est la bienvenue !
              </info-helper>
            </template>
          </form-field></b-col>
      </b-row>
      <b>Propriétés</b>
      <b-table striped hover :items="formField_properties"></b-table>
      <b>Slots</b>
      <b-table striped hover :items="formField_slots"></b-table>
    </p>

  </div>
</template>

<script>
import { BaseBaliseRef, BCollapseRef } from "@/components/Viewers/Code/BalisesRef";
import CollapsePanel from '../../components/Panels/CollapsePanel.vue';
import FormField from '../../components/Panels/FormField.vue';
import InfoHelper from '../../components/InfoHelper.vue';
export default {
  components:{ BaseBaliseRef, BCollapseRef, CollapsePanel, FormField, InfoHelper },
  data(){
    return {
      sample_panel_title: "Exemple de titre",
      sample_panel_collapsable: true,
      sample_panel_visible: true,

      sample_formfield_caption: "Libellé d'exemple",
      sample_formfield_required: false,
      sample_formfield_compactmode: false,
      sample_formfield_infohelper: false,

      formField_properties: [
        { nom: 'compactMode', type: 'Boolean', obligatoire: 'Non', valeur_par_défaut: 'false', description: '' },
        { nom: 'caption', type: 'String', obligatoire: 'Non', valeur_par_défaut: '""', description: '' },
        { nom: 'required', type: 'Boolean', obligatoire: 'Non', valeur_par_défaut: 'false', description: '' },
        { nom: 'prepend', type: 'String', obligatoire: 'Non', valeur_par_défaut: '', description: '' },
        { nom: 'append', type: 'String', obligatoire: 'Non', valeur_par_défaut: '', description: '' },
        { nom: 'validFeedback', type: 'String', obligatoire: 'Non', valeur_par_défaut: '', description: ''},
        { nom: 'invalidFeedback', type: 'String', obligatoire: 'Non', valeur_par_défaut: '', description: '' },
        { nom: 'state', type: 'Boolean', obligatoire: 'Non', valeur_par_défaut: 'true', description: '' },
        { nom: 'description', type: 'String', obligatoire: 'Non', valeur_par_défaut: 'Null', description: '' },
      ],
      formField_slots: [
        { nom: 'caption', description: 'Libellé du champ.<br/>Gère l\'affichage par défaut du libellé incluant le symbole indiquant, le cas échéant, que le champ est obligatoire et le signe indiquant, le cas échéant, qu\'une aide est disponible.', contenu_par_defaut: '' },
        { nom: 'caption-text', description: 'Permet de personaliser le rendu du libellé (texte).', contenu_par_defaut: 'La valeur de la propriété "caption".' },
        { nom: 'info-helper-placeholder', description: 'Permet de renseigner, le cas échéant, le contenu de la bulle d\'aide, rendant alors visible l\'indicateur de présence d\'aide.' },
      ]

    }
  },
  mounted(){
    this.$hideLoader();
  },
  watch: {
    $route(to, from) {
        if (to !== from) {
            console.log("/Security/Users/Edit.vue > route id changed : ", to);
            if(to.hash){
              window.location = to.hash;
            }
        }
    },
},
}
</script>
